import Vue from 'vue';
// eslint-disable-next-line
import vueCustomElement from 'vue-custom-element';
import MuseumFilter from './components/MuseumFilter.vue';

// Enable the plugin
// Vue.use(MuseumFilter);

// Register your component
Vue.customElement('museum-filter', MuseumFilter, {
  // Additional Options: https://github.com/karol-f/vue-custom-element#options
  shadow: false,
});
