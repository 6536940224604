<script>
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    selectedMarker: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      infoWindow: null,
    };
  },

  computed: {
    position() {
      return {
        lat: this.selectedMarker.geometry.coordinates[1],
        lng: this.selectedMarker.geometry.coordinates[0],
      };
    },
  },

  watch: {
    selectedMarker() {
      if (this.selectedMarker) {
        this.infoWindow.setContent(
          this.selectedMarker.properties.pin_description,
        );
        this.infoWindow.setPosition(this.position);
        this.infoWindow.setOptions({
          pixelOffset: new this.google.maps.Size(0, -30),
        });
        this.infoWindow.open(this.map);
      }
    },
  },

  mounted() {
    this.infoWindow = new this.google.maps.InfoWindow({});

    this.infoWindow.addListener('closeclick', () => {
      this.$emit('closed');
    });
  },
  render() {
    return null;
  },
};
</script>
