<template>
  <div v-if="Boolean(this.spiderfier)">
    <slot :spiderfier="spiderfier" />
  </div>
</template>

<script>
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      spiderfier: null,
    };
  },

  mounted() {
    if (this.map) {
      this.spiderfier = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        basicFormatEvents: true,
        keepSpiderfied: true,
      });
    }
  },
  beforeDestroy() {
    this.spiderfier.setMap(null);
  },
  render() {
    return null;
  },
};
</script>
