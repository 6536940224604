<template>
  <input
    class="search-input form-control"
    ref="searchFilter"
    type="search"
    v-model="query"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  name: 'SearchFilter',
  props: {
    value: null,
    placeholder: String,
  },
  data() {
    return {
      query: this.value,
    };
  },
  methods: {
    setSearchFilter(value) {
      this.query = value;
    },
  },
  watch: {
    query(val) {
      this.$emit('input', val);
    },
  },
  mounted() {
    // oninput hack helaas nodig voor samsung browser die alleen na spatie een change geeft
    this.$refs.searchFilter.oninput = (event) => {
      this.setSearchFilter(event.target.value);
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .search-input {
    height: 33px;
  }
</style>
