<script>
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
    icon: {
      type: Object,
      required: true,
    },
    markerCluster: {
      type: Object,
      required: false,
    },
    spiderfier: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      markerObj: null,
      infoWindow: null,
    };
  },

  computed: {
    position() {
      const lat = this.marker.geometry.coordinates[1];
      const lng = this.marker.geometry.coordinates[0];

      // Coordinates for central Drenthe
      const defaultLat = 52.85;
      const defaultLng = 6.6;


      // Check if lat or lng are zero, and return the default value if so
      if (lat === null || lng === null) {
        return {
          lat: defaultLat,
          lng: defaultLng,
        };
      }

      return {
        lat,
        lng,
      };
    },
  },

  mounted() {
    const { Marker } = this.google.maps;
    this.markerObj = new Marker({
      position: this.position,
      map: this.map,
      icon: this.icon,
    });

    if (this.markerCluster) {
      this.markerCluster.addMarker(this.markerObj);
    }

    if (this.spiderfier) {
      this.spiderfier.addMarker(this.markerObj, () => {
        this.$emit('marker-selected', this.marker);
      });
    } else {
      // spiderfier catches all clicks, but if we don't have spiderfier
      // let the marker handle the click
      this.markerObj.addListener('click', () => {
        this.$emit('marker-selected', this.marker);
      });
    }
  },
  beforeDestroy() {
    if (this.markerCluster) {
      this.markerCluster.removeMarker(this.markerObj);
    }
    this.markerObj.setMap(null);
  },
  render() {
    return null;
  },
};
</script>
