<template>
  <div class="google-map-wrapper">
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
  props: {
    mapConfig: Object,
    apiKey: String,
  },

  data() {
    return {
      google: null,
      map: null,
    };
  },

  async mounted() {
    if (window.google) {
      this.google = window.google;
      this.initializeMap();
    } else {
      const loader = new Loader({
        apiKey: this.apiKey,
        version: 'weekly',
        // libraries: ['places'],
      });

      loader
        .load()
        .then(() => {
          this.google = window.google;
          this.initializeMap();
        })
        .catch(() => {
          // do something
        });
    }
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
    },
  },
};
</script>

<style scoped>
.google-map-wrapper {
  width: 100%;
  height: 100%;
}

.google-map {
  width: 100%;
  height: 100%;
}
</style>
