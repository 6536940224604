<template>
  <div>
    <v-select multiple v-model="selected" :options="options" :placeholder="placeholder">
      <span slot="no-options">-</span>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'ClassifierSelect',
  components: {
    vSelect,
  },
  props: {
    filterData: null,
    placeholder: String,
  },
  data() {
    return {
      selected: null,
      options: [],
    };
  },
  created() {
    this.loadOptions();
  },
  methods: {
    loadOptions() {
      const { terms } = this.filterData;
      const options = terms.map(term => ({
        id: term.id,
        label: term.name,
        fa_icon: term.fa_icon,
      }));
      options.sort((a, b) => a.label.localeCompare(b.label));
      this.options = options;
    },
  },
  watch: {
    selected(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss">
  @import "node_modules/vue-select/src/scss/vue-select.scss";
</style>
