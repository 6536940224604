<script>
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    geojson: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      geoJsonObj: null,
    };
  },

  mounted() {
    this.geoJsonObj = this.map.data.addGeoJson(this.geojson);
    this.map.data.setStyle({
      fillColor: '#cb3c2c',
      strokeWeight: 1
    });
  },
  render() {
    return null;
  },
};
</script>
