<template>
  <div v-if="Boolean(this.markerCluster)">
    <slot :markerCluster="markerCluster" />
  </div>
</template>

<script>
import MarkerClusterer from '@google/markerclustererplus';

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    imagePath: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      markerCluster: null,
    };
  },

  mounted() {
    if (this.map) {
      this.markerCluster = new MarkerClusterer(this.map, [], {
        imagePath: this.imagePath,
        maxZoom: 15,
      });
    }
  },
  beforeDestroy() {
    this.markerCluster.setMap(null);
  },
  render() {
    return null;
  },
};
</script>
